import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

import Sidebar from '../src/Components/Sidebar';
import HeroSection from '../src/Components/Hero';

import randomOtter from '../src/img/randomotter.gif'
import cloud from '../src/img/cloud.png'
import SambinoTwitter from '../src/img/sambinotwitter.jpg'
import SambinoReal from '../src/img/sambinoreal.jpg'
import sneakPeek from '../src/img/SneakPeek.gif'

import {BrowserRouter as Router} from 'react-router-dom'

import { FaBars, FaDiscord, FaTwitter } from 'react-icons/fa'
import { FiPlus } from 'react-icons/fi'
import {Nav, NavbarContainer, SocialIcon, MobileIcon, NavMenu, NavItem, NavLinks, Socials, NavBtn, Foot, FootIcon, CopyRight} from '../src/Components/Navbar/NavbarElements'
import { MintPicture, MintItem, OtterText, AboutBox, AboutContainer, AboutArea, TeamArea, AboutTeam, TeamPic, TeamPics, TeamText, TeamTitle, FaqContainer, TeamIcon} from '../src/Components/MintSection/MintElements';
import { RoadmapContainer, RoadmapText, RoadmapArea, Milestone} from "./Components/Roadmap/RoadmapElements";
import Grid from "@material-ui/core/Grid"
import AccordionC from './Components/FAQ/AccordianElements'

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  const[isOpen, setIsOpen] = useState(false)
  const[clicked, setClicked] = useState(false)
  
   const toggle = () => {
       setIsOpen(!isOpen)
}

  return (
    <main>

    <Router>
    <>
    
      <Sidebar isOpen={isOpen} toggle={toggle}/>
    
        <Nav> 
            <NavbarContainer>
                <Socials>
                <SocialIcon href="http://twitter.com/outcastotters">
                    <FaTwitter />
                </SocialIcon>
                <SocialIcon href="https://discord.gg/jQECYpurkm">
                    <FaDiscord />
                </SocialIcon>
                </Socials>

                
                <MobileIcon >
                    <FaBars onClick={toggle}/>
                </MobileIcon>

                <NavMenu>
                    <NavItem>
                        <NavLinks to ='about'
                        smooth={true} duration={500} spy={true} exact='true' offset={-80}
                        >About</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='roadmap'
                        smooth={true} duration={500} spy={true} exact='true' offset={-79}
                        >Roadmap</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to ='team'
                        smooth={true} duration={500} spy={true} exact='true' offset={-80}
                        >Team</NavLinks>
                    </NavItem>
                </NavMenu>

                <NavBtn>
                <div style={{flexDirection: "column", lineHeight: 1, fontSize: 20, marginRight: "5px", color: '#fff'}}>     
                 {wallet && ( <p> {shortenAddress(wallet.publicKey.toBase58() || "")}</p>)}

                  {wallet && <p> {(balance || 0).toLocaleString()} SOL</p>}
                 </div>

                 <ConnectButton disabled={!isActive}>{wallet ? "Connected" : "Coming Soon"}</ConnectButton>


                </NavBtn>
                {/* Great place for Phantom button possibly.*/}

            </NavbarContainer>
        </Nav>

        <HeroSection/>
    
      <AboutContainer {...AboutArea}>
       
      <MintContainer>
        <MintItem>
          <MintPicture src = {sneakPeek}/>

          {wallet && <MintButton
              disabled={isSoldOut || isMinting || !isActive}
              onClick={onMint}
              variant="contained"
              style={{padding: '0 55px', fontSize: 35, marginBottom: 15, marginTop: 10}}
            >
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "MINT"
              )
            ) : (
              <Countdown
              date={startDate}
              onMount={({ completed }) => completed && setIsActive(true)}
              onComplete={() => setIsActive(true)}
              renderer={renderCounter}
            />
              )}
            </MintButton>
}

         {wallet && <div>
          0.15 SOL
          
          <p>Supply: {itemsRedeemed}/{itemsAvailable}</p>
          </div>
          }
          </MintItem>
          </MintContainer>

          <MintItem>
          <AboutBox>
            <OtterText>
                <h1>About the Otters</h1>
                <p> </p>
                <p>1,001 Lost Otters on the Solana network.</p>
                  <p>100+ mouse-drawn traits</p>
                  <p>991 Programatically Generated</p>
                  <p>10 Hand Generated</p>
                
           
            </OtterText>
          </AboutBox>
          <AboutBox>
            <OtterText>
                <h1>MINT COUNTDOWN</h1>
                <p> </p>
                  <Countdown date={startDate} onComplete={({ completed }) => completed && setIsActive(isActive)} renderer={renderCounter}/>
                  <p>(DATE TBD)</p>

            </OtterText>
          </AboutBox>    

          </MintItem>

         

      </AboutContainer>
  
    
      <RoadmapContainer {...RoadmapArea}>
        <Grid container style={{ height: '100%', width: '55%', paddingTop: 50, placeSelf: 'center'}}>

            <Grid item xs={12} lg={6}>

         
           
                <RoadmapText>
                <Milestone>Milestone #1</Milestone>
                 <h3>Mint 1,001 Otters</h3>
                 <p>Price: 0.00 SOL (FREE)</p>
                 <p>(Date & Time: TBA)</p>
                </RoadmapText>
    
          </Grid>

            <Grid item xs={12} lg={6}>
              </Grid>  
              <Grid item xs={12} lg={6}>
          
          </Grid>  
          <Grid item xs={12} lg={6}>
     
  
                <RoadmapText>
                <Milestone>Milestone #2</Milestone>
                 <h3>Marketplace Listings!</h3>
                 <p>Alpha.Art, Magic Eden, and DigitalEyes</p>
                 <p>are first on the agenda</p>
                </RoadmapText>

          </Grid>  

            <Grid item xs={12} lg={6}>


               
                <RoadmapText>
                <Milestone>Milestone #3</Milestone>
                 <h3>DONATION TO OTTER PRESERVATION</h3>
                 <p>International Otter Survival Fund</p>
                 <p>30% of Royalties</p>
                </RoadmapText>

              </Grid>
            <Grid item xs={12} lg={6}>
          
              </Grid>  
              <Grid item xs={12} lg={6}>
          
          </Grid>  
          <Grid item xs={12} lg={6}>
          

               
                <RoadmapText style={{backgroundImage: cloud}}>
                <Milestone>Milestone #4</Milestone>
                  <h3>Games & Contests!</h3>
                 <p>Games, Tournaments, and Contests</p>
                 <p>*Voted on by holders*</p>
                </RoadmapText>

          </Grid>  
          <Grid item xs={12} lg={6}>
          
               
                <RoadmapText>
                <Milestone>Milestone #5</Milestone>
                 <h2>SAMBINO COLLECTIVE</h2>
                 <p> Sambino teams up with real artists </p>
                 <p>**coming soon**</p>
                </RoadmapText>

          </Grid>  
          
         </Grid>
      </RoadmapContainer>

      <AboutContainer {...TeamArea} >
     
          <AboutTeam>
            <TeamTitle>sambino</TeamTitle>
            <TeamPics>
              <TeamPic src = {SambinoTwitter}/>
              <TeamPic src = {SambinoReal}/>
            </TeamPics>
            <TeamText>Art & Development</TeamText>
            <TeamIcon href="http://twitter.com/cryptosambino" style={{color: '#000'}}>
                    <FaTwitter />
                </TeamIcon>
          </AboutTeam>

      </AboutContainer>

      <AboutContainer>
          <AboutTeam>
            <FaqContainer>
              <AccordionC/>
            </FaqContainer>
           </AboutTeam>
      </AboutContainer>


      <Foot>
                <Socials>
                <FootIcon href="http://twitter.com/outcastotters">
                    <FaTwitter />
                </FootIcon>
          
                <FootIcon href="https://discord.gg/jQECYpurkm">
                    <FaDiscord />
                </FootIcon>
                </Socials>
      </Foot>


      </>
    </Router>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
