import styled from 'styled-components'
import { IconContext } from 'react-icons/lib'
import { FiPlus } from 'react-icons/fi'
import { FaTimes } from 'react-icons/fa'
import { Data } from './Data'
import {useState} from 'react'



const AccordionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
        justify-content: center;
        position: relative;
        background: transparent;
`

const Container = styled.div`
position: relative;
justify-content: center;

`
const Wrap = styled.div` 
    width: 100%;
    background-color: white;
    padding: 25px;
    height: 100px;
    align-content: center;
    display: flex;
   border-style: solid;
   border-color: black;
   margin-top: 5px;
    justify-content: center;
    cursor: pointer;
   span {
       margin-right: 4rem;
   }

   h1 {
       font-size: 35px;
   }

   @media screen and (max-width: 1060px)
    {
        transition: 0.8s all ease;
        margin-left: 42px;
        font-size: 20%;
        height: 90%;
        width: 90%;
    }
    @media screen and (max-width: 400px)
    {
        transition: 0.8s all ease;
        margin-left: 252px;
        font-size: 20%;
        height: 90%;
        width: 40%;
    }
`

const Dropdown = styled.div` 
background: white;
color: black;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-top: 5px;
border: 3px solid black;
font-size: 23px;
padding: 25px;
line-height: 2;

@media screen and (max-width: 960px)
    {
        transition: 0.8s all ease;
        margin-left: 42px;
        font-size: 15;
        height: 90%;
        width: 90%;
    }
    @media screen and (max-width: 400px)
    {
        transition: 0.8s all ease;
        margin-left: 252px;
        font-size: 15;
        height: 90%;
        width: 40%;
    }
`


const AccordionC = () => {
const [clicked, setClicked] = useState(false)

const toggle = index => {
    if(clicked === index) {
        return setClicked(null)
    }

    setClicked(index)
}

  return(
      <IconContext.Provider value={{ color: 'black', size: '25px'}}>
      <AccordionSection>
            <Container>
            {Data.map((item, index) => {
                return(
                    <>
                    <Wrap onClick = {() => toggle(index)} key={index}>
                        <span>{clicked === index ? <FaTimes /> : <FiPlus />}</span>
                    <h1>{item.question}</h1>
                    </Wrap>
                    {clicked === index ? (
                            <Dropdown>
                            <p>{item.answer}</p>
                            </Dropdown>

                    ) : null}
                
                    </>
                )
            })}

            </Container>

      </AccordionSection>
      </IconContext.Provider>
  )  
};

export default AccordionC;