import React from 'react'
import Video from '../../videos/video.mp4'
import Header from '../../img/Header.gif'
import { HeroContainer, HeroBg, VideoBg, AutoPlayVideo } from './HeroElements'

const HeroSection = () => {
    return (
        <HeroContainer>
            <HeroBg >
            <VideoBg src={Header}/>
            </HeroBg>
        </HeroContainer>
    )
}

export default HeroSection
