import styled from "styled-components";
import  VideoBanner  from '../../videos/video.mp4';


export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 750px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1260px)
    {
        transition: 0.8s all ease;
        height: 600px;
       
    }
    @media screen and (max-width: 760px)
    {
        transition: 0.8s all ease;
        height: 350px;
        margin-top: 50px;
       
    }
    @media screen and (max-width: 460px)
    {
        transition: 0.8s all ease;
        height: 220px;
        margin-top: 50px;
       
    }

`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

 
 
`

export const AutoPlayVideo = () => 
(
    <div dangerouslySetInnerHTML={{
        __html: `
        <video
        loop
        muted
        autoplay
        playsinline
        >
        <source src=${VideoBanner} type='video/mp4' />
        </video>
        `
        
    }}
    
    />
);

export const VideoBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;

`

