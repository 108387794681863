import styled from "styled-components";
import backgroundImg from '../../img/otterbg.jpg'
import {FaTimes} from 'react-icons/fa'

export const MintPicture = styled.img`
height: 500px;
width: 500px;
margin: 15px;
border-radius: 15px;

@media screen and (max-width: 960px)
    {
        transition: 0.8s all ease;
        height: 400px; 
        width: 400px;
    }

    @media screen and (max-width: 650px)
    {
        transition: 0.8s all ease;
        height: 350px; 
        width: 350px;
    }    
`

export const AboutArea = {
    id: 'about',
}

export const TeamArea = {
    id: 'team'
}



export const AboutContainer = styled.div`
    background-image: url(${backgroundImg});
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: fit-content;
    position: relative;
    z-index: 3;
    margin-top: 3px;
    margin-bottom: 15px;
    overflow: hidden;
  
    @media screen and (max-width: 1060px)
    {
        transition: 0.8s all ease;
        flex-direction: column;
        padding: 25px;
        line-height: 1;
    
    }
    

`

export const MintItem = styled.div`
    position: relative;
    height: 100%;
    
    font-size: 35px;
    padding: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 768px)
    {
        transition: 0.8s all ease;
    }
`
export const OtterText = styled.div`
    font-size: 25px;
    padding: 40px;
    align-items: center;
    text-align: center;

`

export const AboutBox = styled.button`
   border-radius: 50px;
   width: 600px;
   padding: 0 22px;
   margin: 12px;
   border-width: 5px;
   border-color: darkgray;
   right: 0;
  
    h1{
        padding-bottom: 15px;
    }

   
   @media screen and (max-width: 960px)
    {
        transition: 0.8s all ease;
        width: 490px; 
    }

    @media screen and (max-width: 760px)
    {
        transition: 0.8s all ease;
        width: 390px;
    }
`
export const AboutTeam = styled.div`
height: 80%;
width: 600px;
font-size: 35px;
display: flex;
flex-direction: column;
position: relative;
align-items: center;
text-align: center;
align-content: center;

`

export const TeamIcon = styled.a`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
`

export const TeamPic = styled.img`
border-radius: 50%;
height:250px;
width: 250px;
margin: 25px;

@media screen and (max-width: 460px)
    {
        transition: 0.8s all ease;
        width: 150px;
        height: 150px;
        justify-content: center;
    }

`
export const TeamPics = styled.div`
width: 600px;
display: flex;
flex-direction: row;
height: 100%;
align-items: center;
align-content: center;
justify-content: center;

`

export const TeamTitle = styled.div`
   width: 600px;
    font-size: 140px;
    padding: 45px;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 500px)
    {
        transition: 0.8s all ease;
        width: 400px;
        font-size: 90px;
        padding: 15px
    }
`

export const TeamText = styled.div`
    width: 600px;
    font-size: 25px;
    padding: 40px;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 460px)
    {
        transition: 0.8s all ease;
        padding: 25px
    }
`

export const FaqWrapper = styled.div`
    width: 100%;
    background-color: white;
    height: fit-content;
    font-size: 30px;
    padding: 15px;
    align-content: center;
    display: flex;
   border-style: solid;
   border-color: black;
   margin-top: 2px;
`
export const FaqBtn = styled.button`

`

export const FaqQuestion = styled.div`
    width: 90%;
    background-color: white;
`

export const AnsClose = styled(FaTimes)`
    color: #000;
`

export const AnsIcon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;

`

export const AnswerText = styled.aside`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: gray;
    display: flex;
    align-items: center;
    text-align: center;

    transition: 0.3s ease-in-out;
    opacity: ${({QisOpen }) => (QisOpen ? '100%' : '0')};
    top: ${({ QisOpen }) => (QisOpen ? '0' : '-100%')};
`

export const FaqContainer = styled.div`
    background-image: url(${backgroundImg});
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 850px;
    margin-top: 3px;
    margin-bottom: 15px;
    overflow: hidden;
  
`